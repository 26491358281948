import { useAppSelector } from "@/store/hooks";
import {
  IoCheckmark,
  IoVideocamOutline,
  IoDocumentTextOutline,
  IoCardOutline,
  IoStarOutline,
} from "react-icons/io5";
import { TbConfetti } from "react-icons/tb";

import { twMerge } from "tailwind-merge";

const selectIcon = (name: number, flowSteps: any) => {
  const fallbackIcon = <IoStarOutline />;

  if (flowSteps[name]?.icon) {
    switch (flowSteps[name].icon) {
      case "star": {
        return <IoStarOutline />;
      }
      case "camera": {
        return <IoVideocamOutline />;
      }
      case "document": {
        return <IoDocumentTextOutline />;
      }
      case "creditcard": {
        return <IoCardOutline />;
      }
      case "confetti": {
        return <TbConfetti />;
      }
      default: {
        return fallbackIcon;
      }
    }
  } else {
    return fallbackIcon;
  }
};

export default function Header() {
  const wizard = useAppSelector((state) => state.wizard);

  const { steps: flowSteps, step: currentStep } = wizard;

  const getStep = (step: number) => {
    // @ts-ignore
    return wizard?.steps[step];
  };

  return (
    <ol className="flex justify-center items-center">
      {Object.keys(flowSteps).map((key) => {
        const stepIcon = selectIcon(+key, flowSteps); // fallback
        const { done } = getStep(+key) || {};
        return (
          <li key={key} className="flex items-center">
            <div className="flex relative justify-center items-center">
              <span
                className={twMerge(
                  "z-10 flex items-center justify-center w-10 h-10 rounded-full lg:h-12 lg:w-12 shrink-0 bg-gray-200 text-gray-400",
                  done && "bg-secondary text-white",
                  wizard.step === +key &&
                    "bg-primary text-white transition-all",
                )}
              >
                {done && currentStep !== +key ? <IoCheckmark /> : stepIcon}
              </span>
              <span
                className={twMerge(
                  "absolute w-10 h-10 rounded-full z-0",
                  currentStep === +key && "bg-primary/50",
                  // @ts-ignore
                  !wizard?.steps[currentStep]?.done && "animate-pulse-ring",
                )}
              />
            </div>
            <hr
              className={twMerge(
                "border-2 w-6 sm:w-10",
                +key === Object.keys(flowSteps).length && "hidden",
                done && "border-secondary",
              )}
            />
          </li>
        );
      })}
    </ol>
  );
}
